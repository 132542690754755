import { ClerkLoaded, ClerkProvider } from '@clerk/nextjs'
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js'
import type { NextPage } from 'next'
import { ThemeProvider } from 'next-themes'
import type { AppProps } from 'next/app'
import type { ReactElement, ReactNode } from 'react'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'tailwindcss/tailwind.css'

import Layout from 'components/layout'
import GoogleTagManager from 'components/marketing/google_tag_manager'
import PostHog from 'components/marketing/posthog'
import Rollbar from 'components/marketing/rollbar'

import '../styles/globals.css'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
)

export type NextPageWithLayout = NextPage & {
  // eslint-disable-next-line
  getLayout?: (page: ReactElement) => ReactNode
}

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function defaultLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? defaultLayout

  return (
    <ClerkProvider>
      <ThemeProvider attribute="class">
        <ClerkLoaded>
          <Rollbar />
          <PostHog />
          <GoogleTagManager />
          {getLayout(<Component {...pageProps} />)}
        </ClerkLoaded>
      </ThemeProvider>
    </ClerkProvider>
  )
}
